<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >Gerenciador de Contratos</v-card-title
      >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleRegisterManagementsDilog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Novo gerenciamento</span>
      </v-tooltip>
      <v-data-table
        light
        :headers="headers"
        :items="managements"
        :items-per-page="10"
        class="elevation-0 opacityTable mx-15"
      >
        
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1 mr-3"
                @click="editManagements(item)"
                x-small
                color="primary"
                dark
                ><v-icon center x-small> edit </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Editar gerenciamento</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1"
                @click="confirmDelManagements(item)"
                x-small
                color="red"
                dark
                ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Excluir gerenciamento</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <RegisterManagements
      :updateManagements="updateManagements"
      :editManagementsData="editManagementsData"
      :toogleRegisterManagementsDilog="toogleRegisterManagementsDilog"
      :managementsDialog="managementsDialog"
    />
  </div>
</template>

<script>
import RegisterManagements from "./RegisterManagements.vue";
import MyFilters from "../../filters/genericFilters";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  mounted() {
    this.getManagements();
  },
  methods: {
    async getManagementsById(id) {
      let line;
      var obj = {
        url: "/partner/contact/getById",
        query: { id: id },
        method: "post",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        line = response.data[0];
      });
      return line;
    },
    updateManagements(id) {
      this.getManagements();
      // if (id) {
      //   this.getManagementsById(id).then((line) => {
      //     let index = this.managements.findIndex(
      //       (managements) => managements.id === id
      //     );
      //     this.managements.splice(index, 1);
      //     this.managements.splice(index, 0, line);
      //   });
      // }
    },
    editManagements(data) {
      this.editManagementsData = { ...data };
      this.managementsDialog = true;
    },
    confirmDelManagements(data) {
      let obj = {
        message: `Você deseja excluir o gerenciamento de contrato <b>${data.name}</b>?`,
        title: "Excluir gerenciamento",
        callback: (_) => {
          this.delManagements(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delManagements(data) {
      let obj = {
        url: "/api/contract-manager/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.managements.findIndex(
          (provider) => provider.id === data.id
        );
        this.managements.splice(index, 1);
        let opts = {
          message: `Gerenciamento <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleRegisterManagementsDilog() {
      this.editManagementsData = null;
      this.managementsDialog = !this.managementsDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    getManagements() {
      var obj = {
        url: "/api/contract-manager/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.managements = [...response.data];
      });
    },
  },
  data() {
    return {
      windowH: "580",
      editManagementsData: null,
      managementsDialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "Prefixo", value: "prefix", sortable: false },
        { text: "Endpoint", value: "endpoint", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      managements: [],
    };
  },
  components: {
    RegisterManagements,
  },
};
</script>

<style>
</style>