<template>
  <v-row justify="center">
    <v-dialog light v-model="managementsDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{ formData.id ? "Editar gerenciamento" : "Novo gerenciamento" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn
              @click="toogleRegisterManagementsDilog"
              class="mt-2"
              color="red"
              fab
              icon
              dark
              x-small
              absolute
              top
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="manageContractForm" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="formData.name"
                    label="Nome do serviço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formData.prefix"
                    label="Prefixo"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formData.endpoint"
                    label="Endpoint"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-0 mx-3"
          style="border-top: 1px solid rgb(220, 220, 220)"
        >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterManagementsDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="saveManagements"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetManagementsForm = {
  name: "",
  prefix: "",
  endpoint: "",
  company_id: "",
};
export default {
  methods: {
    saveManagements() {
      //console.log("servicsave");
      if (!this.$refs.manageContractForm.validate()) {
        return;
      }

      let url = this.formData.id
        ? "/api/contract-manager/update"
        : "/api/contract-manager/create";
      // this.formData.company_id = this.selectedCompany.id;
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let opts = {
          message: `Gerenciamento ${
            this.formData.id ? "atualizado" : "criado"
          } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.updateManagements(response.data);
        this.toogleRegisterManagementsDilog();
      });
    },
  },
  watch: {
    managementsDialog(v) {
      if (!v) {
        this.$refs.manageContractForm.resetValidation();
        this.formData = { ...resetManagementsForm };
      } else {
        if (this.editManagementsData) {
          this.formData = this.editManagementsData;
        }
      }
    },
  },
  mounted() {},
  props: {
    updateManagements: Function,
    editManagementsData: Object,
    toogleRegisterManagementsDilog: Function,
    managementsDialog: Boolean,
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
      valid: true,
      formData: { ...resetManagementsForm },
    };
  },
};
</script>

<style>
</style>